@import '../../node_modules/react-datepicker/dist/react-datepicker.css';
@import "../style/_variables";

body {
  background-color: $white !important;
  overflow-x: hidden;

  .label-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 15px;
    color: #495861;
    font-weight: 400;

    .radio-button {
      position: absolute;
      top: 0;
      left: 0;
      height: 22px;
      width: 22px;
      border-radius: 50%;
      border: 3px solid #ccc;
      margin-top: 1px;

      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }
  }

  button {
    &:focus, &:active {
      outline: none;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #f1f5f7 inset;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.center-content {
  overflow-x: hidden;
  overflow-y: auto;
  width: calc(100% - 73px);

  @media (max-width: 576px) {
    min-height: calc(100vh - 120px);
  }

  @media all and (max-width: 599px) {
    width: calc(100% - 56px);
  }

  .menu-item {
    overflow: hidden;
    @media (max-width: 576px) {
      min-height: calc(100vh - 120px);
    }

    &.customers {
      .left-content {
        padding-top: 20px;

        .dropdown-toggle:after {
          display: none;
        }

        .dropdown-toggle {
          background: none;
          border: none;
          cursor: pointer;
        }

        .add-customer-btn {
          font-size: 11px;
          width: 100%;
          background: #fff;
          border: 1px solid #ccc;
          border-radius: 3px;
          cursor: pointer;
        }

        .top-content {
          .add-customer-content {
            width: 280px;
            height: 240px;
            background: #fff;
            border-radius: 4px;
            border: 1px solid #ccc;
            padding: 0 !important;

            .top-header-content {
              padding: 7px 10px;
              background: #f1f5f7;
              font-size: 14px;
            }

            .main-content {
              padding: 10px;

              .add-customer-btn {
                height: 40px;
                width: 50%;
                background-color: $main-blue;
                opacity: .8;
                color: #fff;
                font-size: 13px;
                border-radius: 6px;
              }
            }
          }
        }

        .customers-list-content {
          border-radius: 5px;
          background: #eaeaea;

          .customer {
            .photo {
              margin: 0 10px;
              img {
                border-radius: 50%;
                border: 1px solid #ccc;
              }
            }
          }
        }
      }

      .personal-info-content {
        label {
          width: 80px;
          min-width: 80px;
          margin-bottom: 0;

          &.switch {
            height: 80px;
          }

          img {
            border-radius: 50%;
            border: 1px solid #cccccc;
            height: 80px;
          }

          &:hover {
            .upload-file {
              display: flex;
            }
          }
          .upload-file {
            position: absolute;
            top: 24%;
            font-size: 22px;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            background-color: #efefef;
            display: none;
            align-items: center;
            justify-content: center;
            left: 20%;
            cursor: pointer;
          }
        }
      }
    }

    &.calendar {
      #calendar-container {
        padding: 20px;
      }
    }

  }

}

// switch button style
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 22px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $main-blue;
  opacity: .8;
}

input:focus + .slider {
  box-shadow: 0 0 1px $main-blue;
  opacity: .9;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.add-staff-modal {
  width: 600px;
}
.add-staff-modal-upload {
  padding: 0;
}
.wizard-container,
.create-staff-modal-dialog,
.companies,
.modal-body,
#customers {
  .wizard-navigation {
    &.individual {
      .nav {
        justify-content: center;
      }

      .moving-tab {
        left: 5%;
      }
    }

    li {
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .moving-tab {
      background-color: $main-blue !important;
      box-shadow: 0 16px 26px -10px $main-blue, 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px $main-blue;
    }
  }

  .form-item {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border-radius: 10px;
    height: 40px;

    &:active, &:focus {
      outline: none !important;
      border-color: #ced4da !important;
      -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3) !important;
      -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3) !important;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3) !important;
    }

    &::placeholder {
      font-size: 13px;
    }
  }

  .business-details {
    width: 80%;
    margin: 10px auto;

    &.phone-details {
      margin-top: 20px !important;
    }

    label {
      text-align: left;
    }
    .logo-label {
      font-size: 18px;
    }

   .selectpicker {
      width: 100%;
    }

   .bootstrap-select {
     width: 100% !important;
   }

   .btn {
     &.dropdown-toggle {
       background-color: unset;
       width: 100% !important;
       border-radius: 10px;
       border: 1px solid #ced4da;
       height: 45px;
       box-shadow: unset !important;
       padding-left: 13px !important;
     }

     &:hover {
       box-shadow: none !important;
     }

     &:active, &:focus {
       outline: none !important;
       box-shadow: none !important;
     }
   }

   .form-group .form-item {
     display: block;
     width: 100%;
     padding: .375rem .75rem;
     line-height: 1.5;
     color: #495057;
     background-color: #fff;
     background-clip: padding-box;
     border: 1px solid #ced4da;
     transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
     border-radius: 10px;
     height: 40px;
     &:active, &:focus {
       outline: none !important;
       border-color: #ced4da !important;
       -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3) !important;
       -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3) !important;
       box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3) !important;
     }
     &::placeholder {
       font-size: 13px;
     }
   }

   .form-item-select {
     .css-yk16xz-control {
       display: flex;
       width: 100%;
       padding: 0 .220rem;
       line-height: 1.5;
       color: #495057;
       background-color: #fff;
       background-clip: padding-box;
       border: 1px solid #ced4da;
       transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
       border-radius: 10px;
       height: 40px;

       &:active, &:focus {
         outline: none !important;
         border-color: #ced4da !important;
         -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3) !important;
         -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3) !important;
         box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3) !important;
         height: 40px !important;
       }

       &::placeholder {
         font-size: 13px;
       }

       .css-1hwfws3 {
         height: 100%;
         padding-top: 0;
         padding-bottom: 0;
       }
     }
     .css-1pahdxg-control {
       height: 40px;
       padding: 0 .220rem;
       border-radius: 10px;
       border-color: #ced4da !important;
       box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3) !important;
       -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3) !important;
       -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3) !important;
     }
   }
  }

  .time-zone {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 40px;
  }

  .business-hours {
    width: 80%;
    margin: 20px auto 0;

    .bootstrap-select {
      width: 120px !important;

      .dropdown-toggle {
        background-color: unset !important;
        color: #000 !important;
      }
    }

    .day-content {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .hours-select {
        select {
          width: 85px;
          height: 30px;
        }
        span {
          margin: 0 5px 0 9px;
        }
      }
      .day-name {
        min-width: 110px !important;

        span {
          font: normal normal normal 16px/25px $main-font;
          letter-spacing: 0;
          color: $main-grey;
        }
      }

    }
  }

  .info-text {
    margin: 10px 0 0 !important;
    font-size: 28px;
  }
}

.working-schedule {
  .time-zone.working {
    width: 250px;
  }
}

#calendar-container {
  .worker-name {
    margin-top: 15px;
    font-size: 2em;
    text-align: center;
    margin-bottom: 0;
  }
}

.image-container {
  height: 100vh !important;
  background-position: center center;
  background-size: cover;
  position: absolute;
  width: 100vw !important;
  top: 0;
  left: 0;

  .container {
    height: 100%;
    margin-left: auto !important;

    .row:first-child {
      height: 100%;
    }
  }
}

// custom checkbox style

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  font-size: 15px;
  margin-left: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

p {
  &.error-message {
    color: #F12B2C;
    font: normal 500 12px/16px Nunito Sans, sans-serif;
    margin-top: 6px;
    margin-bottom: 6px;
  }
}

.error-message-content {
  display: flex;
  align-items: center;

  p {
    margin-left: 8px;
    color: #FF2A2A;
  }

  svg {
    display: block;
    height: 16px;
    width: 16px;
  }
}

.error-message-content:empty {
  height: 25px;
}

a:-webkit-any-link {
  text-decoration: none;
  color: $main-blue;
}

a {
  &:hover {
    text-decoration: none !important;
  }
}

.modal {
  &.fade.show {
    display: block;
    margin-top: 5%;
  }

  .modal-dialog {
    z-index: 999;
    max-width: 730px;
    text-align: center;
    font-size: 16px;
    font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    .modal-header {
      padding: .75rem 1.25rem;
      margin-bottom: 0;
      background-color: rgba(0,0,0,.03);
      border-bottom: 1px solid rgba(0,0,0,.125);
      justify-content: center;
    }
  }
}

.loading {
  .container {
    margin-left: auto !important;

    &.full-height {
      height: calc(100vh - 130px);
    }
  }
}

.MuiSelect-select:focus {
  background-color: $white !important;
}

.menu-item.create-appointment,
.menu-item.appointments,
.menu-item.messages,
.menu-item.reminders,
.menu-item.customers,
.menu-item.notifications,
.menu-item.industries,
.menu-item.customers {
  width: 100%;
  background-color: #fff;
  font-family: 'Poppins', sans-serif;

  .general-content {
    font-family: $main-font;
    color: black;
    width: 100%;
    margin: 0 auto;
    .page-top-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 11px 20px 10px 20px;
      width: 100%;
      border-bottom: 1px solid #CBCDCF;
      background: #fff;
      h3 {
        font-weight: normal;
        font-size: 22px;
      }
    }

    .panel {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      .main-content {
        width: calc(100% - 80px);
      }
    }
  }
}

// custom checkbox
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 22px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch input:checked + .slider {
  background-color: $main-blue;
  opacity: .8;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px $main-blue;
  opacity: .9;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input:disabled {
  cursor: no-drop;
  background-color: hsl(0, 0%, 95%) !important;
  color: hsl(0, 0%, 60%) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.invite-customer, .existing-user {
  div:nth-child(1)[aria-hidden="true"] {
    background-color: rgba(153, 153, 153, 0.32) !important;
  }
}

#second-navbar {
  min-height: 64px;
  position: relative;

  .title-companyName {
    @media all and (max-width: 966px) {
      margin-top: 12px;
    }
  }

  .button-box {
    height: 39px;
    display: inline-block;

    button {
      height: 100%;
      padding: 4px 16px;
      font: normal 500 14px/19px Nunito Sans, sans-serif;
    }
  }

  .company-name-box {
    align-self: center;
  }

  .company-name-long {
    white-space: nowrap;
    width: 220px;
    overflow: hidden;
    text-overflow: ellipsis; ;
    font-size: 20px;

    @media all and (max-width: 375px) {
      width: 120px;
    }

    @media all and (max-width: 959px) {
      font-size: 16px;
    }
  }

  .divider-title {
    border-right: 2px solid $main-grey;
    padding-right: 24px;
    margin-right: 24px;
    align-self: center;
  }

  .appointments-fields {
    display: flex;
    align-items: center;

    @media all and (max-width: 1024px) {
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .search-staff,
    .search-customer {
      margin-right: 20px;

      svg {
        color: $gray;
      }
    }
  }

  .MuiFormControl-root.search-appointment,
  .MuiFormControl-root.search-customer,
  .MuiFormControl-root.search-staff {
    width: 270px;

    @media all and (max-width: 552px) {
      margin-right: 0;
    }
    svg {
      color: $gray;
    }
  }

  .filterIcon {
    margin-right: 9px;
  }

  .buttons-box {
    align-self: center;
  }
}

.working-schedule .time-zone,
.breaking-schedule .time-zone{
  text-align: center;
  margin-bottom: 10px;

  span {
    font: normal normal normal 16px/25px $main-font;
    color: $black;
  }

  .tab-item {
    text-align: center;
  }
}

.tab-list .tab-item {
 margin: 0 auto;
}

.tab-content {
  margin-top: 50px;
  @media (max-width: 576px) {
    margin-top: 85px;
  }
}

.tabs-top-bar {
  position: fixed !important;
  z-index: 99 !important;
  top: 130px;
  @media all and (max-width: 1024px) {
    .top-bar-right, .top-bar-right-customer {
      margin: 0 auto 15px auto;
    }
  }
  @media (max-width: 576px) {
    top: 214px;
  }
}

.dropdown-toggle::after {
  display: none !important;
}

.css-26l3qy-menu {
  z-index: 99999;
 }

.MuiTabs-root {

  .MuiTab-root {
    margin-right: 15px;
    min-width: unset;
  }
}

.MuiPopover-paper {
  max-width: unset !important;
}

//customers-customers-line
.customers-customers-line {
  min-height: 222px;
  flex-wrap: wrap;
  border-radius: 5px;
  opacity: 1;

  .col-auto.customer-item {
    margin: 0 !important;
    display: flex;
    padding: 0 20px;

    .actions-dropdown-customer {
      padding: 0 !important;
      position: relative;

      .image-content {
        margin-top: 26.79px;
        text-align: center;

        img {
          width: 47%;
        }
      }
    }
  }
  .company-name,
  .customer-name,
  .staff-name,
  .industry-name {
    margin-top: 14px;
    padding: 0 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    font-size: 14px !important;
    &:hover {
      color: $main-blue;
    }
  }
}

// custom-card
.custom-card {
  margin-bottom: 20px;
  border: 0.30000001192092896px solid $main-grey;
  width: 150px;
  height: 150px;
  flex-wrap: wrap;
  position: relative;
  background: $white;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 0 1px 3px $black;

  &:hover {
    background: $white 0 0 no-repeat padding-box;
    box-shadow: 0 1px 3px $black;
    border: 1px solid $main-blue;
    border-radius: 2px;
    opacity: 1;
    transition: .5s;
  }

  .mask {
    position: absolute;
    top: 6px;
    right: 0;
    display: flex;
    align-items: flex-start;
    justify-content: right;
    border-radius: 5px;
    transition: .3s all ease-in-out;
    opacity: 1;

    button {
      padding: 0;
    }

    &:hover {
      svg {
        path {
          fill: $main-blue;
        }
      }
    }
  }
}

select.Mui-disabled {
  background: #f2f2f2;
}

.select-min-params {
  min-width: 200px;
  height: 42px;
}

.alert-title,
.dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.box-general {
  padding: 0 10px;

  .card-box-img {
    max-width: 140px;
    min-width: 140px;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3) !important;
  }
}

.card-box-avatar {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.menu-items-list {
  padding-right: 8px;
  padding-left: 8px;

  .menu-items {
    transition: all 0.5s ease;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 8px;
    margin-bottom: 8px;

    .MuiTypography-displayBlock {
      display: inline-block;
    }

    &:hover {
      background-color: #41A1E3;
    }
  }

  @media all and (max-width: 600px) {
    .menu-items {
      border-radius: 0;
    }

    & {
      padding-right: unset;
      padding-left: unset;
    }
  }
}


.menu-role-item {

  &:hover {
    background-color: #4CA0FF !important;
  }

}

.sub-menu-item {
  padding-left: 6px;
  background-color: #006ce5;
}

.active-menu-item {

  .menu-items {
    background-color: #41A1E3;
  }
}

.MuiFormControl-marginDense {
  margin: 0 !important;
}

#account-type,
#personal-details,
#business-details {
  background-image: url("../assets/img/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  max-height: 100vh;
}

.personal-container, .business-container {
  margin: 0 auto !important;
}

.primary-btn {
  font: 500 14px/19px $main-font;
  color: #fff;
  min-width: 120px !important;
  max-width: 120px;
  width: 100%;
  transition: .5s !important;
}

.box-cursor {
  cursor: pointer;
}

.MuiDialogTitle-root {
  padding: 4px 4px 4px 12px !important;
}

.MuiDialogContent-dividers {
  padding: 16px !important;
}

.MuiIconButton-root:hover {
  background-color: rgba(60,64,67,0.078) !important;
}

#fixed-box, #fixed-box-info {
  margin: unset;
  width: 100%;
  padding: 16px;
  border-radius: 2px;

  .rect-shape {
    width: 100% !important;
    margin-right: 0 !important;
  }

  .text-row {
    margin-top: 0 !important;
  }
}

.info-box {
  padding: 16px;
  border: 1px solid #ececec;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0px rgba(0, 0, 0, 0.14), 0 1px 8px 0px rgba(0, 0, 0, 0.12);

  .info-box-title {
    padding-bottom: 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.23);
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 500;

    .info-title-text {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.title-abbr {
  cursor: pointer !important;
}

@media all and (min-width: 500px) {
  .MuiDialog-paperWidthXs,
  .MuiDialog-paperWidthSm {
    min-width: 470px;
  }
}

@media all and (min-width: 500px) {
  .MuiDialog-paperWidthMd {
    min-width: 470px;
    max-width: 500px !important;
  }
}
.MuiButton-containedPrimary:hover {
  background-color: #62B1E8 !important;
}
.auth-box {
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px;
  background-color: $white;
}

.auth-logo {
  max-width: 200px;

  img {
    width: 100%;

    @media all and (max-width: 375px) {
      max-width: 170px;
    }
  }
}

.login-header {
  padding: 40px 0;
  @media all and (max-width: 375px) {
    padding: 20px 0;
  }
}

.auth {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.MuiTab-textColorSecondary.Mui-selected {
  color: $main-blue !important;
}

.MuiTabs-root, .MuiTab-root {
  min-height: 0 !important;
}

.MuiFormControlLabel-root {
  margin-bottom: 0;
}

.selectSize {
  min-width: 120px !important;
}

.already-added {
  td {
    vertical-align: middle !important;
  }
}
.invite-individual-customer {
  .error-message {
    padding-left: 0;
    padding-top: 5px;
  }
}

.elipsis {
  width: 100%;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 75%;
  }
}

.elipsis-small p {
  width: 60%;
}

.checkbox-accept {
  margin-right: 5px !important;
  margin-top: 8px;

  .MuiTypography-body1 {
    font-size: 10px !important;
  }
}
.MuiAlert-standardSuccess {
  background-color: #DBE2DB !important;
}
.MuiAlert-standardSuccess {
  .MuiAlert-message {
    color: #2A3D2A;
  }
}
.MuiAlert-standardSuccess .MuiAlert-icon {
    color: #9DCC9B !important;
}
.MuiSvgIcon-fontSizeSmall {
  color: #2A3D2A;
}
.MuiAlert-standardInfo {
  background-color: #DFECF4 !important;
}
.MuiAlert-standardInfo .MuiAlert-icon {
  color: #62B1E8 !important;
}
.MuiAlert-standardInfo {
  .MuiSvgIcon-fontSizeSmall {
    color: #2E3A43;
  }
}
.MuiAlert-standardInfo {
  .MuiAlert-message {
    color: #2E3A43;
  }
}
.accept-checkbox {
  font-size: 10px !important;
}

.confirm-password {
  margin-bottom: 8px;
}

.privacy-txt {
  color: $main-blue;
}

.no-appointment {
  color: $main-grey;
}

.remove-industry-avatar {
  position: absolute;
  right: 0;
  cursor: pointer;

  .remove-icon {
    display: none;
  }
}

.thumbInner {
  &:hover {
    .remove-icon {
      display: block;
      font-size: 30px;
      padding: 5px;
      border-radius: 50%;
      background-color: #cccccc;
      color: $white;
    }
  }
}

.option-logo {
  margin-right: 15px;
  img {
    vertical-align: middle;
    width: 20px;
    height: 20px;
  }
}

.settings-icon {
  vertical-align: middle;
}

.MuiDialogActions-root.appointment-bottom-btn {
  display: inline;
  .bottom-btn {
    display: flex;
    justify-content: space-between;
  }
}
.MuiSwitch-track {
  background-color: #62B1E8 !important;
}
.addStaffDivider {
  margin: 10px 0 !important;
}
// Super Styles by Astx
//[class*="drawerClose"] ~ main header .staff-content {
//  max-width: calc(100vw - 56px - 153px - 300px);
//}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: $main-grey;
  border-radius: 5px;
}
.MuiMenuItem-root.input-menu {
  width: 100% !important;
  // padding: 5px 1px;
  &:hover {
    background: none !important;
  }
  .input-field {
    font-size: 12px;
  }
}
.input-menu {
  padding: 5px 1px;
}
.fc-custom-button {
  background: transparent;
  border: none;
  position: absolute;
  top: 15px;
  left: 20px;
  font-size: 25px;
  padding: 0;

  &:hover {
    background: transparent;
    border: none;
  }
  &:active {
    background: transparent;
    border: none;
  }
}

.notes-from-creator {
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  font: normal 400 14px/19px Nunito Sans, sans-serif;
  color: #A4A4A4;

  &:focus {
    outline: none;
  }
}

.notes-from-appointment {
  min-width: 100%;
  max-width: 100%;
  min-height: 50px;
  max-height: 200px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
}

.info-working-schedule-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: rgb(13, 60, 97);
  background-color: rgb(232, 244, 253);
}

.card-box-root-service {
  overflow: unset !important;

  &.card-plus-box {
    height: unset;
  }

  .content-card-box, .card-box-root{
    height: unset;

  }

  .card-box-root {
    .MuiTypography-h5 {
      width: unset;
    }
  }

  .service {
    padding: 12px;
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0px rgba(0, 0, 0, 0.14), 0 1px 8px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
    margin-bottom: 10px;
  }
}

.accordion-box {
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0px rgba(0, 0, 0, 0.14), 0 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.accordion-category .MuiAccordionSummary-content {
  margin: 0;
}

.MuiAlert-root.MuiAlert-filledSuccess {
  position: absolute;
  top: 3%;
  right: 1%;
  width: 350px;
  height: 50px;
  padding: 10px;
  z-index: 1500;
  display: flex;
  align-items: center;
  .MuiAlert-icon svg {
    width: 35px;
    height: 35px;
  }
}

.duration {
  margin-right: 15px !important;
}

.company-name {
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis; ;
  font-size: 20px;
}

.companySelect {
  max-width: 220px;
}

.companySelectOptions {
  width: 220px !important;
  text-overflow: ellipsis;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.industries-list {
  min-width: 240px;
}

.appointment-status {
  width: 146px;
  .MuiSelect-icon {
    color: #eeeeee;
  }
}

.profession-label {
  padding-left: 10px;
  font-size: 12px;
  color: #8c949d;
}
.closed-days-button {
  margin-top: 10px;
}

.service-drawer {
  .MuiFormControlLabel-root {
    margin-left: 0;

    .MuiTypography-body1 {
      color: #393939;
      font: normal 400 14px/19px Nunito Sans, sans-serif;
    }
  }

  .MuiAutocomplete-endAdornment {
    top: 6px;
  }

  .MuiInputBase-input.Mui-disabled {
   background-color: #DADADA !important;
  }
}

#appointment-menu-item {
  &.MuiListItem-gutters {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &.MuiMenuItem-root {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &.MuiListItem-button:hover {
    background-color: transparent !important;
  }
}

.MuiInputBase-root.Mui-disabled {
  background-color: $white !important;

  input:disabled {
    background-color: $white !important;
  }
}

.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border: 1px solid #DADADA !important;
}

.navbar-tool {
  .MuiInputBase-inputMarginDense {
    padding-bottom: 3px;
  }

  label + .MuiInput-formControl {
    margin-top: 0 !important;
  }
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

}

.verification-code-input {
  input:not(:placeholder-shown) {
    border-bottom: 2px solid #0282DA;
  }

  & input[value=""] {
      border-bottom: 2px solid #A4A4A4;
  }
  & input[value="N"] {
      border-bottom: 2px solid #A4A4A4;
  }

  & .MuiInputBase-input {
    color: #0282DA;
    font: normal 600 24px/33px Nunito Sans, sans-serif;
    text-align: center;
  }
}

#crisp-chatbox {
  z-index: 1299;

  .cc-dc5e {
    bottom: 60px !important;
  }
}

.topBar-items {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 6px;

  .company-avatar-btn {
    padding: 6px;

    .topBar-company-avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }

  .notification-icon-btn {
    padding: 6px;
  }
}

.topBar-account-paper {

  &.MuiPaper-elevation1 {
    background: $white;
    box-shadow: 0 2px 18px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  .topBar-account-box {
    padding: 0 !important;

    .detail-menu-item {
      padding: 15px 24px !important;

      &.MuiListItem-button {
        &:hover {
          border-radius: 10px 10px 0 0;
          background-color: unset;
          cursor: default;
        }
      }

      .company-name {
        margin: 0;
        font: normal 700 16px/20px Nunito Sans, sans-serif;
        color: $gray;
      }

      .company-industry {
        margin: 0;
        font: normal 500 12px/20px Nunito Sans, sans-serif;
        color: #707070;
      }
    }
    .logOut-menu-item {
      border-top: 1px solid #E9E9E9;
      padding: 15px 24px !important;

      &.MuiListItem-button {
        &:hover {
          border-radius: 0 0 10px 10px;
        }
      }
    }
  }
}

.MuiPickersBasePicker-pickerView * {
  text-transform: capitalize;
}

.react-tel-input {
  .form-control {
    width: 100% !important;
    height: 48px !important;
  }
}
